<template>
  <div id="app">
    <div id="nav">
      <b-row>
        <b-col sm="12" md="12" xl="12" offset-xl="" lg="12">
          <b-row>
            <b-col sm="12" md="3" xl="4" class="text-left logo">
              <b-link :to="{ path: `/` }" class="brand-logo">
              <b-img alt="parrotize logo" height="50" :src="src"> </b-img>
              </b-link>
            </b-col>
            <b-col sm="12" md="9" xl="8" class="navButtons" align-self="end">
<!--              <b-button href="https://app.parrotize.com/register2" class="mr-3 underline" variant="link">
                Features
              </b-button>-->
              <b-button to="/faq" class="mr-3 underline" variant="link"> FAQ </b-button>
              |
              <b-button href="https://app.parrotize.com/login"  class="mr-1 underline" variant="link"> Login </b-button>
              <b-button  to="/pricing" class="mr-1 underline" variant="link"> Pricing </b-button>
              <b-button href="https://app.parrotize.com/order" class="mr-3 underline px-4 createeventbutton" variant="transparent" pill style="background-color: #FF4803!important;color: white!important;">
                Create Event
              </b-button>
<!--              <b-button href="https://app.parrotize.com/login" class="mr-1 underline" variant="link">
                <globe-icon size="1.25x"></globe-icon>
                En
              </b-button>-->

            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!--<router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>-->
    </div>
    <router-view />
  </div>
</template>

<script>
import { BRow, BCol, BButton, BImg,BLink } from "bootstrap-vue";
import { GlobeIcon } from 'vue-feather-icons'
export default {
  components: {
    GlobeIcon,
    BRow,
    BCol,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      src: require("./assets/logo-parrotize.png"),
    };
  },
};
</script>
<style>

</style>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Nunito");
$primary:purple;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.numbers:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 30px;
  background-color: #4d61fc;
}
.numbers {
  position: relative;
  font-size: 50px;
  font-weight: 700;
}

.numbers span {
  vertical-align: baseline;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (max-width: 640px) {
  .logo{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .navButtons{
    align-self: center;
    justify-content: center;
    justify-items: center;
    text-align: center!important;
  }
  .createeventbutton{
    display: none!important;
  }
}
.navButtons{
  text-align: right;
}
#featuresArea .title {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  line-height: 1.3;
  color: #303030;
}

#featuresArea .detail {
  margin-bottom: 1em;
  margin-top: 1em;
  font-family: "Lora", serif;
  line-height: 1.4;
  color: #303030;
}
.btn::after{
  content:'';
  background: #f7931e;
}
.btn-primary {
  //@include button-variant(#f7931e, darken(#f7931e, 7.5%), darken(#f7931e, 10%), lighten(#f7931e,5%), lighten(#f7931e, 10%), darken(#f7931e,30%));
}
.underline{
  text-align: center;
  font: normal normal normal 14px/30px Josefin Sans!important;
  letter-spacing: 0px;
  color: #100C1E!important;

  opacity: 1;
}
.partnerImage {
  height: 80px;
}
</style>
