
var i = 8
var timezoneChangedManually = false

import moment from "moment";
import tz from "moment-timezone";

function getTimeZoneOffset() {
    return moment.tz(moment.tz.guess()).format("z");
}

function getLocalTimeZone() {
    return moment.tz.guess()
}

function timeZoneList() {
    return moment.tz.names();
}

function changeDefaultTimeZone(timezone) {
    moment.tz.setDefault(timezone)
    this.timezoneChangedManually = true
}

function getLocaleDateTime(time, format) {
    if (!timezoneChangedManually)
        moment.tz.setDefault("UTC");
    var sourceDate = moment(time);
    var timeZoneDifferencedDate = sourceDate.utcOffset(getTimeZoneOffset())
    return (typeof format != 'undefined') ? timeZoneDifferencedDate.format(format) : timeZoneDifferencedDate
}

function getUTCDateTime(time, format) {
    var sourceDate = moment(time);
    var utcTime = sourceDate.utc();
    return (typeof format != 'undefined') ? utcTime.format(format) : utcTime

}

export default {
    getLocaleDateTime,
    getTimeZoneOffset,
    getUTCDateTime,
    timeZoneList,
    getLocalTimeZone,
    changeDefaultTimeZone,
}