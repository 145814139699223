<template>
  <b-form @submit="submitForm" @submit.prevent>
    <b-row>
      <b-col xl="12" >
        <b-row>
          <b-col md="6">
            <b-form-group label="First Name" label-for="mc-first-name">
              <b-form-input
                v-model="form.name"
                id="mc-first-name"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Last Name" label-for="mc-last-name">
              <b-form-input
                v-model="form.surname"
                id="mc-last-name"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="City" label-for="mc-city">
              <b-form-input
                v-model="form.city"
                id="mc-city"
                placeholder="City"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Country" label-for="mc-country">
              <b-form-input
                v-model="form.country"
                id="mc-country"
                placeholder="Country"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Company" label-for="mc-company">
              <div class="form-label-group">
                <b-form-input
                  v-model="form.company"
                  id="mc-company"
                  placeholder="Company"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="mc-email" label="Email">
              <div class="form-label-group">
                <b-form-input
                  v-model="form.email"
                  id="mc-email"
                  type="email"
                  placeholder="Email"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label-for="textarea-default">
              <div>
                <label for="textarea-default">Your message</label>
                <b-form-textarea
                  id="textarea-default"
                  placeholder="Your message"
                  rows="3"
                  v-model="form.message"
                />
              </div>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col class="justify-content-end text-right">
            <b-button type="submit" variant="primary" class="mr-1">
              Submit
            </b-button>
            <b-button type="reset" variant="outline-secondary">
              Clear
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BToast
} from "bootstrap-vue";

import axios from "axios";
export default {
  components: {
      BToast,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        surname: "",
        city: "",
        country: "",
        company: "",
        message: "",
      },
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
        appendToast:true,
         autoHideDelay: 5000,
      });
    },
    submitForm(event) {
      console.log(event);
     // this.makeToast("success");
       axios
        .post(
          "https://api.parrotize.com/endpoint/contact/sendticket",
          this.form
        )
        .then((response) => {
          this.form = {
            email: "",
            name: "",
            surname: "",
            city: "",
            country: "",
            company: "",
            message: "",
          };
         this.makeToast("success");
         alert("Your form submitted.")
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="sss" scoped>
</style>