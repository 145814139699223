<template>
  <div class="px-2 cardwrapper">
    <b-link
        :to="{ path: 'events/detail/test-test-' + this.$vnode.key }"
        class="font-weight-bold d-block text-nowrap cardlink"
    >
    <b-card
        :title="eventName"
        class="mb-2 mr-1 mt-3 custom-card-values text-left"
        header-tag="header"
        img-alt="Image"
        img-top
        style="border-radius: 1rem"
        tag="article"
    >
      <template #header style="padding: 0!important;">
        <div
            class="card-img-top"
            v-if="eventBannerImage"
            style="
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 15px 15px 0 0 ;"
            v-bind:style="{'background-image': 'url(' + eventBannerImage + ')' }"
        ></div>
        <div
            class="card-img-top"
            v-else
            style="
            background-repeat: no-repeat;
            background-size: cover;
            background-color: white;
            border-radius: 15px 15px 0 0 ;"

            v-bind:style="{'background-image': 'url(' + src + ')' }"
        ></div>
      </template>
      <b-overlay :show="overlayVisible" rounded="sm">

          <label style="text-align: left;
                 font: normal normal normal 14px/19px Nunito;
                  letter-spacing: 0px;
                  color: #8B8991;
                  opacity: 1;">{{ eventSubject }} </label><br/>
          <div class="">
            <CalendarIcon size="21" style="color: #f7931e"/>
            <label id="" style="text-align: left;
                   font: normal normal normal 12px/16px Nunito;
                   letter-spacing: 0px;
                   color: #100C1E;
                   opacity: 1;">
              {{ getLocaleDateTime(eventDate, "ddd,DD MMMM YYYY") }}</label>
            <br/>
            <WatchIcon size="21" style="color: #f7931e"/>
            <label style="text-align: left;
                    font: normal normal normal 12px/16px Nunito;
                    letter-spacing: 0px;
                    color: #100C1E;
                    opacity: 1;">
              {{ getLocaleDateTime(eventDate, "HH:mm") }} -
              {{ getLocaleDateTime(eventEndDate, "HH:mm") }}
            </label>
          </div>
      </b-overlay>
    </b-card>
    </b-link>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BLink,
  BRow,
  BCol,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import CalendarIcon from "vue-feather-icons/icons/CalendarIcon";
import WatchIcon from "vue-feather-icons/icons/WatchIcon";
import momentHelper from "../assets/libs/useMoment.js";

export default {
  components: {
    BBadge,
    BCard,
    BButton,
    CalendarIcon,
    WatchIcon,
    BAvatar,
    BLink,
    BRow,
    BCol,
    BOverlay,
  },
  data() {
    return {
      src: require("../assets/logo-parrotize.png"),
      overlayVisible: false,
    };
  },
  methods: {
    getLocaleDateTime: momentHelper.getLocaleDateTime,
    getTimeZoneOffset: momentHelper.getTimeZoneOffset,

  },
  props: {
    eventID: {
      type: [Number],
      default: -1,
    },
    eventName: {
      type: [String, null],
      default: "Garanti BBVA Partners Meetup",
    },
    eventSubject: {
      type: [String, null],
      default: "“Güven” Zor Zamanlarda Temel Lider…",
    },
    eventDate: {
      type: [String, null],
      default: "Sun,16 March 2022",
    },
    eventEndDate: {
      type: [String, null],
      default: "Sun,16 March 2022",
    },
    eventTime: {
      type: [String, null],
      default: "18:00 - 18:30",
    },
    eventBannerImage: {
      type: [String, null],
      default: "https://picsum.photos/600/300/?image=25",
    },
  },
};
</script>

<style scoped lang="scss">

#card:hover {
  height: 297px !important;

  #cardactionButton {
    display: flex !important;
  }
}

#cardactionButton {
  display: none;
}

@media screen and (max-width: 1920px) {
  .custom-card-values {
    max-width: 342px !important;
    width: 342px !important;
    //max-height:250px!important;
    height: 344px !important;
  }
  .card-img-top {
  }
}

@media screen and (max-width: 1280px) {
  .custom-card-values{
  max-width: 342px !important;
  width: 342px !important;
  //max-height:250px!important;
  height: 344px !important;
  }
  .card-img-top {
    //max-width: 100%-5 !important;
  }
}
.cardlink:hover{
    text-decoration: none;

}
@media screen and (max-width: 600px) {

  .custom-card-values {
    max-width: unset !important;
    max-height: unset !important;
    width: unset !important;
  }
  .card-img-top {
  }
}

.card .card {
  box-shadow: 0px 3px 6px #00000029 !important;
}
.cardwrapper:hover{
  transition:all 0.3s ease-out;
  transform: translateX(5px) translateY(-5px);
}
.custom-card-values:hover{
  box-shadow:0px 1px 1px rgba(0, 0, 0, 0.10);
}
.card-img-top {
  justify-content: center;
  min-width: 100%;
  min-height: 10em;
}
.card-header{
  padding: 0!important;
  border-bottom: unset!important;
}
.custom-card-values {
}

.card .card-title {
  text-align: left;
  font: normal normal 600 14px/19px Nunito !important;
  letter-spacing: 0px;
  color: #100c1e;
  opacity: 1;
  margin-bottom: 0px !important;
}

.dark-layout {
  .card-title {
    color: #ffffff !important;
  }
}

#eventSubject {
  text-align: left;
  font: normal normal normal 0.9rem/0.9rem Nunito;
  letter-spacing: 0px;
  color: #8b8991;
  opacity: 1;
  margin-top: 4.95px;
  margin-bottom: 20px !important;
}

#eventCardFooterWrapper {
  margin-top: 12px;
}

#eventCardFooterWrapper > span {
  margin-right: -7px !important;
}

#eventCardFooterWrapper > span:last-child {
  margin-right: 3px !important;
}
</style>
