import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
Vue.config.productionTip = false;

/*
import formwizard from "vue-form-wizard";

Vue.use(formwizard)
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

*/
require('./assets/fonts/feather/iconfont.css') // For form-wizard

//import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'
 
//Vue.use(VueFormGenerator)
import { BVToastPlugin } from 'bootstrap-vue'

Vue.use(BVToastPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
