<template>
  <div class="home">
    <b-img :src="require('../assets/logo-parrotize.png')" alt="background"
           style="display: none"></b-img>
    <b-img :src="require('../assets/back-ground.png')" alt="background"
           style="background-repeat: no-repeat; position: absolute;z-index: -9999;left:0px;top: 0;width: 100%"></b-img>

    <b-row class="mt-5 pb-5">
      <b-col cols="12" offset="0" style="padding: 0!important;">
        <b-row style="padding-bottom: 10rem">
          <b-col lg="12" md="12" xl="12">
            <b-row>
              <b-col>
                <b-row class="justify-content-center">
                <span class="text-center bigtext">
                  LANGUAGE
                </span>
                  <span
                      class="text-center smalltext"
                      style="
                        background: rgba(247, 147, 30,1);
                        font: normal normal 600 4em/1.5em Khmer MN;
                        align-self: flex-end !important;
                        display: flex;
                        padding-bottom: 5px;
                        background:linear-gradient(rgba(254,177,0,0) 10%, rgba(254,177,0,0) 40%, rgba(254,177,0,1) 40%, rgba(254,177,0,1) 60%,rgba(254,177,0,0) 60%) repeat scroll 50% 50%;"
                  >IS</span>
                </b-row>
                <b-row class="justify-content-center">
                <span
                    class="text-center smalltext align-content-start"
                    style="
                        margin-right: 0px!important;
                        margin-left:100px;
                        background: rgba(30,222,247,1);
                        background:linear-gradient(rgba(0,202,202, 0) 10%, rgba(0,202,202, 0) 20%, rgba(0,202,202,1) 20%, rgba(0,202,202,1) 40%,rgba(0,202,202,0) 40%) repeat scroll 50% 50%;"
                >NOT A
                </span>
                  <span class="text-center bigtext">BARRIER</span>
                </b-row>
                <p
                    class="text-center mt-4"
                    style="
                    text-align: center;
                    font: normal normal normal 1em/1.5em Nunito;
                    letter-spacing: 0px;
                    color: #100C1E;
                    opacity: 1;"
                >
                  Parrotize is an
                  interpretation delivery
                  app digitalizing <br>
                  simultaneous translation.
                </p>
              </b-col>
            </b-row>

            <b-row class="text-center justify-content-center" no-gutters>
              <b-button class="mr-3 mt-5 underline py-2 text-center px-5" href="https://app.parrotize.com/order" pill
                        style="background-color: #FF4803!important;color: white!important;"
                        variant="transparent">
                Let's Create Your First Event
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="text-center justify-content-center" no-gutters>
          <people style="height: 100%" width="800"/>
          <div style="height: 2vh;width: 100%; background-color: black"></div>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="events.length>0" class="mt-1 " cols="10" style="padding-top: 7rem">
      <b-col>
        <b-row class="justify-content-between pb-4">
          <h4 class="" style="text-align: center;
                                      font: normal normal 600 30px/37px Josefin Sans;
                                      letter-spacing: 0px;
                                      color: #100C1E;
                                      opacity: 1;">Upcoming Events
          </h4>
          <b-button href="https://app.parrotize.com/login" style="text-align: center;
                                          font: normal normal normal 20px/27px Nunito;
                                          letter-spacing: 0px;
                                          color: #100C1E;
                                          opacity: 1;"
                    variant="transparent">See more >
          </b-button>
        </b-row>
        <b-row class="justify-content-center">
          <template>
            <div class="
                    d-flex
                    flex-wrap
                    justify-content-center
                    ml-5
                    mr-5
                    custom-card-wrapper
                    ">
              <testCard
                  v-for="event in events"
                  :event-banner-image="event.eventBannerImage"
                  :event-date="event.eventStartDate"
                  :event-name="event.eventName"
                  :event-subject="event.eventDescription"
                  :eventEndDate="event.eventEndDate"
                  :eventID="event.id"
              />
            </div>
          </template>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5" style="overflow: hidden; padding-bottom: 10rem ">
      <div style="position: relative;z-index: -9999;left: -1rem;top: 5rem">
        <fourthsvg width="180"/>
      </div>
      <b-col cols="10" offset="1">
        <b-row>
          <b-col lg="12" md="12" xl="12">
            <b-row>
              <b-col>
                <p class="text-center bigtext" style="font: normal normal 600 4em/1em Josefin Sans;">
                  Are you ready to explore ?
                </p>
                <p class="text-center mt-4" style="
                    text-align: center;
                    font: normal normal normal 20px/27px Nunito;
                    letter-spacing: 0px;
                    color: #100C1E;
                    opacity: 1;">
                  We deliver cloud based, device-free interpretation to audience with an IOS and Android App.
                </p>
              </b-col>
            </b-row>
            <b-row class="text-center justify-content-between mt-3" no-gutters>
              <b-col class="iosbutton">
                <b-button style="background-color: black" aria-label="App store download button" onclick="location.href='https://apps.apple.com/tr/app/parrotize-app/id1442456783'">
                  <availableOnIos height="30"
                  />
                </b-button>
              </b-col>
              <b-col class="androidbutton">
                <b-button style="background-color: black" aria-label="Play store download button"  onclick="location.href='https://play.google.com/store/apps/details?id=com.parrotize'">
                  <availableOnAndroid height="30"/>
                </b-button>
              </b-col>
            </b-row>
            <!--            <b-button class="mr-3 mt-5 underline py-2 text-center px-5" href="#about" pill
                                  style="background-color: #FF4803!important;color: white!important;"
                                  variant="transparent">
                          Request Demo
                        </b-button>-->
          </b-col>
        </b-row>
      </b-col>
      <div style="position: relative; z-index: -9999;left: 89vw;top: -10rem">
        <fifthsvg width="180"/>
      </div>
    </b-row>
    <b-row class="mt-5 pb-5 " style=" background-color: #100C1E">
      <b-col class="footerwrapper" cols="9" lg="9" md="9" offset="1" sm="13">
        <b-row>
          <b-col class="pt-5" lg="8" md="12" xl="6">
            <p style="text-align: left;
                  padding-top: 0.5rem;
                  vertical-align: middle;
                  font: normal normal normal 20px/27px Nunito;
                  letter-spacing: 0px;
                  color: #FFF9EA;
                   opacity: 1;">
              We are offering the best plans for your company, events and conferences.
            </p>
          </b-col>
          <b-col class="pt-5 justify-content-left text-left " lg="4" md="12" xl="2">
            <b-button class="mr-3 underline px-4" href="https://app.parrotize.com/order" pill
                      style="background-color: #FF4803!important;color: white!important;"
                      variant="transparent">
              Create Event
            </b-button>
          </b-col>
          <b-col class="pt-5 justify-content-left text-left " lg="4" md="12" xl="4">
            <b-button v-b-modal.contactform class="mr-3 underline px-4" pill
                      style="background-color: #FF4803!important;color: white!important;"
                      variant="transparent">
              Contact us
            </b-button>
            <b-modal id="contactform" hide-footer size="l" title="Contact Us">
              <contactform/>
            </b-modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-5" lg="3" md="6" xl="2">
            <p style="text-align: left;
                  padding-top: 0.5rem;
                  vertical-align: middle;
                  font: normal normal normal 20px/27px Nunito;
                  letter-spacing: 0px;
                  color: #FFF9EA;
                   opacity: 1;">
              Follow Us!
            </p>
          </b-col>
          <b-col class="pt-5 justify-content-left text-left " lg="9" md="6" xl="10">
            <b-button aria-label="linkedin button" class="mr-3 px-1" href="https://tr.linkedin.com/company/parrotize" rel="noopener " target="_blank"
                      variant="transparent">
              <linkedin-icon style="color: white"></linkedin-icon>
            </b-button>
            <b-button aria-label="twitter button" class="mr-3 px-1" href="https://twitter.com/ParrotizeApp" rel="noopener " target="_blank"
                      variant="transparent">
              <twitter-icon style="color: white;"></twitter-icon>
            </b-button>
            <b-button aria-label="instagram button" class="mr-3 px-1" href="https://www.instagram.com/parrotizecom/" rel="noopener "
                      target="_blank" variant="transparent">
              <instagram-icon style="color: white"></instagram-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-row class="pt-5" lg="2" md="6" xl="1">
            <b-button class="mr-3 footerlinks pl-2" v-b-modal.contactform variant="transparent">
              Support
            </b-button>
            <b-button class="mr-3 footerlinks px-1" :to="{ path: `/termsandconditions` }" variant="transparent">
              Terms & conditions
            </b-button>
            <b-button class="mr-3 footerlinks px-1" :to="{ path: `/privacy` }" variant="transparent">
              Privacy policy
            </b-button>
          </b-row>

        </b-row>
      </b-col>
      <person class="person" width="180"/>
    </b-row>
    <div style="position: absolute;z-index: -9999;left: -7rem;top: 30rem">
      <reddash width="200"/>
    </div>
    <div class="secondsvg" style="position: absolute;z-index: -9999;right:10vw;top: 15rem">
      <secondsvg width="70"/>
    </div>
    <div style="position: absolute;z-index: -9999;right: 0;top: 50rem">
      <thirdsvg width="100"/>
    </div>

  </div>

</template>


<script>
import {AirplayIcon, AtSignIcon, TwitterIcon, InstagramIcon, LinkedinIcon} from "vue-feather-icons";
import axios from "axios";
import testCard from "../components/Card.vue";

import {
  BRow,
  BCol,
  BModal,
  BButton,
  VBModal,
  BImg,
  BCarousel,
  BCarouselSlide,
  BIcon,
} from "bootstrap-vue";
import availableOnIos from "../assets/svg/available-on-the-app-store.svg";
import availableOnAndroid from "../assets/svg/playstore.svg";
import people from "../assets/svg/image_1.svg";
import person from "../assets/svg/image-2.svg";
import reddash from "../assets/svg/1.svg";
import secondsvg from "../assets/svg/2.svg";
import thirdsvg from "../assets/svg/3.svg";
import fourthsvg from "../assets/svg/4.svg";
import fifthsvg from "../assets/svg/5.svg";
import icon1 from "../assets/svg/icon1.svg";
import VueFormGenerator from "vue-form-generator";
import contactform from "../components/contactform.vue";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "Home",
  components: {
    contactform,
    AirplayIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon,
    BIcon,
    BRow,
    testCard,
    BModal,
    BCol,
    BButton,
    BImg,
    availableOnIos,
    availableOnAndroid,
    people,
    person,
    reddash,
    secondsvg,
    thirdsvg,
    fourthsvg,
    fifthsvg,
    icon1,
    BCarousel,
    BCarouselSlide,
    "vue-form-generator": VueFormGenerator.component,
    TabContent,
    FormWizard,
  },
  directives: {
    'b-modal': VBModal
  },
  data() {
    return {
      src: require("../assets/logo-parrotize.png"),
      events: []
    }
  },
  beforeMount() {
    axios.get("https://api.parrotize.com/endpoint/event/getupcomingevents")
        .then((response) => {
          console.log(response);
          this.events = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
  },
  methods: {

    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    onComplete: function () {
      alert("Yay. Done!");
    },
    validateFirstTab: function () {
      return this.$refs.firstTabForm.validate();
    },
    validateSecondTab: function () {
      return this.$refs.secondTabForm.validate();
    },

    prettyJSON: function (json) {
      if (json) {
        json = JSON.stringify(json, undefined, 4);
        json = json.replace(/&/g, "&").replace(/</g, "<").replace(/>/g, ">");
        return json.replace(
            /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
            function (match) {
              var cls = "number";
              if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                  cls = "key";
                } else {
                  cls = "string";
                }
              } else if (/true|false/.test(match)) {
                cls = "boolean";
              } else if (/null/.test(match)) {
                cls = "null";
              }
              return '<span class="' + cls + '">' + match + "</span>";
            }
        );
      }
    },
  },
};

</script>

<style lang="scss">

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Nunito");

@font-face {
  font-family: "Khmer MN";
  src: url('../assets/fonts/KhmerMN.ttf');
}

.numbers:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 30px;
  background-color: #4d61fc;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.btn.rounded-pill:hover {
  transition: all 0.3s ease-out;
  transform: translateX(2px) translateY(-2px);
}

.bigtext {
  font: normal normal 600 8em/1em Khmer MN;
  letter-spacing: 0px;
  color: #100C1E;
  opacity: 1;
}

.smalltext {
  font: normal normal 600 4em/1em Khmer MN;
  letter-spacing: 0px;
  color: #100C1E;
  opacity: 1;
  margin-right: 100px;
}

@media screen and (max-width: 640px) {
  .bigtext {
    font: normal normal 600 3em/1em Khmer MN;
    letter-spacing: 0px;
    color: #100C1E;
    opacity: 1;
  }
  .smalltext {
    font: normal normal 600 1.5em/1em Khmer MN!important;
    letter-spacing: 0px;
    color: #100C1E;
    opacity: 1;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .footerwrapper {
    text-align: center;
  }
  .secondsvg {
    right: 7vw !important;
    top: 25rem !important;
  }
  .iosbutton{
    justify-content: center;
    text-align: center!important;
    margin: 0!important;
  }
  .androidbutton{
    justify-content: center;
    text-align: center!important;
    margin: 0!important;
  }
}

@media screen and (max-width: 1125px) {
  .person {
    display: none;
  }
}

.person {
  margin-top: -23rem;
}
.iosbutton{
  justify-content: center;
  text-align: right;
  margin-right: 0.5rem;
}
.androidbutton{
  justify-content: center;
  text-align: left;
  margin-left: 0.5rem;
}
.numbers span {
  vertical-align: baseline;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 400;
}
.modal-backdrop{
  background-color: #00000082!important;
}
#featuresArea .title {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  line-height: 1.3;
  color: #303030;
}

#featuresArea .detail {
  margin-bottom: 1em;
  margin-top: 1em;
  font-family: "Lora", serif;
  line-height: 1.4;
  color: #303030;
}

.footerlinks {
  text-align: center;
  font: normal normal normal 15px/20px Nunito;
  letter-spacing: 0px;
  color: #FFF9EA !important;
  opacity: 1;
}

.partnerImage {
  height: 80px;
}

.row {
  margin: unset !important;
}
</style>
